import { Injectable } from "@angular/core";

@Injectable()
export class Globals {

    sd_token = 'SD_TOKEN';
    sd_userDetails = 'SD_USERDETAILS';
    sd_role: string = '';
    userdata = 'username=dbcustomer`manual&password=dbcustomer&client_id=sdservices&client_secret=sdservices&grant_type=password';

    user: {
        authority: string,
        loginType: string,
        userId: any,
        userName: string
    } = {
            authority: '',
            loginType: '',
            userId: undefined,
            userName: ''
        };

    resetUser = function () {
        this.user.authority = '';
        this.user.loginType = '';
        this.user.userId = undefined;
        this.user.userName = '';
    }

    isEmpty = function (value: any) {
        if (value == '' || value == null || value == undefined) {
            return true;
        }
    }

    saveLocalData = function (key: any, value: any) {
        localStorage.setItem(key, value);
    }

    getLocalData = function (key: any) {
        return localStorage.getItem(key);
    }

    removeLocalData = function (key: any) {
        localStorage.removeItem(key);
    }

}