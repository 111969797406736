import { Component, ElementRef } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthServiceService } from './services/auth-service.service';
import { Globals } from './globals';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  userdata = 'username=dbcustomer`manual&password=dbcustomer&client_id=sdservices&client_secret=sdservices&grant_type=password';
  sd_token = 'SD_TOKEN';


  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private el: ElementRef,
    public authService: AuthServiceService,
    public globals: Globals
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }


  ngOnInit() {
    if (this.globals.isEmpty(this.globals.getLocalData(this.globals.sd_token)) || this.globals.isEmpty(this.globals.getLocalData(this.globals.sd_userDetails))) {
      console.log('done');
      this.userLogin();
    } else {
      this.globals.user = JSON.parse(this.globals.getLocalData(this.globals.sd_userDetails));
    }
    window.addEventListener('scroll', this.scroll, true); //third parameter
  }
  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
  }

  scroll = (event): void => {
    console.log('scrolling');
    const sticky: HTMLElement = this.el.nativeElement.querySelector('.header_area');
    if (window.scrollY >= 100) { sticky.classList.add('fixed'); }
    else { sticky.classList.remove('fixed'); }
  }

  userLogin = function () {

    this.authService.login('oauth/token', this.userdata)
      .subscribe(res => {
        console.log(res);
        if (!this.globals.isEmpty(res) && !this.globals.isEmpty(res.access_token) && !this.globals.isEmpty(res.authorities[0])) {
          this.globals.saveLocalData(this.globals.sd_token, res.access_token);
          this.globals.saveLocalData(this.globals.sd_userDetails, JSON.stringify(res.authorities[0]));
          this.globals.user = res.authorities[0];
        }
      }, error => {
        console.log(error);
      });
  }

}
