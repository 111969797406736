import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Globals } from '../globals';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {

  constructor(public http: HttpClient, public globals: Globals, public router: Router) { }

  public getAuthHeaders = () => ({
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.getToken()
    })
  });

  public getHeaders = () => ({
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    })
  });

  public getFileHeaders = () => ({
    headers: new HttpHeaders({
      'Authorization': 'Bearer ' + this.getToken()
    })
  });

  public getUrlencodedHeaders = () => ({
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    })
  });


  getNoSecure = function (path: String) {

    return this.http.get(environment.base + path, this.getHeaders());
  }
  postNoSecure = function (path: String, data: any) {

    return this.http.post(environment.base + path, data, this.getHeaders());
  }

  get = function (path: String) {
    if (!this.authorized()) {
      console.log("You are not authorized");
      this.router.navigate(['/login']);
      return;
    }

    return this.http.get(environment.base + path, this.getAuthHeaders());
  }

  post = function (path: String, data: any) {
    if (!this.authorized()) {
      console.log("You are not authorized");
      return;
    }
    // console.log(data);
    return this.http.post(environment.base + path, data, this.getAuthHeaders());
  }

  put = function (path: String, data?: any) {
    if (!this.authorized()) {
      console.log("You are not authorized");
      return;
    }
    // console.log(data);
    return this.http.put(environment.base + path, data, this.getAuthHeaders());
  }

  delete = function(path: String) {
    if(!this.authorized()) {
      console.log("You are not authorized");
      return;
    }

    return this.http.delete(environment.base + path, this.getAuthHeaders());
  }

  login = function (path: String, data: any) {

    return this.http.post(environment.base + path, data, this.getUrlencodedHeaders());
  }

  getToken = function () {

    var tokenString = localStorage.getItem(this.globals.sd_token);

    if (this.isEmpty(tokenString)) {
      return null;
    }
    return tokenString;
  }

  isEmpty = function (value: any) {
    if (value == '' || value == null || value == undefined) {
      return true;
    }
  }

  authorized = function () {

    if (this.getToken()) {
      return true;
    }
    return false;
  }

  uploadNoSecure = function(path: String, file: any) {

    var fd = new FormData();
    fd.append("file", file);

    return this.http.post(environment.base + path, fd);
  }

  upload = function(path: String, file: any) {

    if (!this.authorized()) {
      console.log("You are not authorized");
      return;
    }
    var fd = new FormData();
    fd.append("file", file);

    return this.http.post(environment.base + path, fd, this.getFileHeaders());
  }
}
