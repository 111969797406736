import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact/contact.module').then( m => m.ContactPageModule)
  },
  {
    path: 'deals',
    loadChildren: () => import('./pages/deals/deals.module').then( m => m.DealsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'reviews',
    loadChildren: () => import('./pages/review/review.module').then( m => m.ReviewPageModule)
  },
  {
    path: 'header',
    loadChildren: () => import('./pages/core/header/header.module').then( m => m.HeaderPageModule)
  },
  {
    path: 'footer',
    loadChildren: () => import('./pages/core/footer/footer.module').then( m => m.FooterPageModule)
  },
  {
    path: 'categories',
    loadChildren: () => import('./pages/categories/categories.module').then( m => m.CategoriesPageModule)
  },
  {
    path: 'promotions',
    loadChildren: () => import('./pages/promotions/promotions.module').then( m => m.PromotionsPageModule)
  },
  {
    path: 'add-deals',
    loadChildren: () => import('./pages/add-deals/add-deals.module').then( m => m.AddDealsPageModule)
  },
  {
    path: 'manage-articles',
    loadChildren: () => import('./pages/manage-articles/manage-articles.module').then( m => m.ManageArticlesPageModule)
  },
  {
    path: 'deal-detail/:articleId',
    loadChildren: () => import('./pages/deal-detail/deal-detail.module').then( m => m.DealDetailPageModule)
  },
  {
    path: 'add-blog',
    loadChildren: () => import('./pages/add-blog/add-blog.module').then( m => m.AddBlogPageModule)
  },
  {
    path: 'blog-detail/:blogId',
    loadChildren: () => import('./pages/blog-detail/blog-detail.module').then( m => m.BlogDetailPageModule)
  },
  {
    path: 'manage-blog',
    loadChildren: () => import('./pages/manage-blog/manage-blog.module').then( m => m.ManageBlogPageModule)
  },
  {
    path: 'all-blogs',
    loadChildren: () => import('./pages/all-blogs/all-blogs.module').then( m => m.AllBlogsPageModule)
  },
  {
    path: 'add-blogs',
    loadChildren: () => import('./pages/add-blogs/add-blogs.module').then( m => m.AddBlogsPageModule)
  },
  {
    path: 'policy',
    loadChildren: () => import('./pages/policy/policy.module').then( m => m.PolicyPageModule)
  },
  {
    path: 'query',
    loadChildren: () => import('./pages/query/query.module').then( m => m.QueryPageModule)
  },
{
    path: 'splash-screen',
    loadChildren: () => import('./pages/splash-screen/splash-screen.module').then( m => m.SplashScreenPageModule)
  },{
    path: 'contact-list',
    loadChildren: () => import('./pages/contact-list/contact-list.module').then( m => m.ContactListPageModule)
  },];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    RouterModule.forRoot(routes,{
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
